body {
  margin: 0;
  font-family: 'Share Tech Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'LogicLoop';
  src: local('LogicLoop'), url(./fonts/Logic_Loop.ttf) format('truetype');
}

@font-face {
  font-family: 'Share Tech Mono';
  src: local('Share Tech Mono'), url(./fonts/ShareTechMono-Regular.ttf) format('truetype');
}